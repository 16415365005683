<template>
  <div>
    <div class="formulator__sliders">
      <formulator-slider
        name="Proteína mínima"
        unit="%"
        min="11"
        max="23"
        interval="1"
        v-model="protein"
      />
      <formulator-slider
        name="Energía mínima"
        unit="Mcal"
        min="2.5"
        max="3.4"
        interval="0.1"
        v-model="energy"
      />
      <formulator-slider
        name="FDN máximo"
        unit="%"
        min="-50"
        max="-10"
        interval="1"
        v-model="fdn"
        reverse
      />
      <formulator-slider
        name="CNE mínimo"
        unit="%"
        min="35"
        max="75"
        interval="1"
        v-model="cne"
      />
    </div>
    <hr class="sliders-separator">
    <formulator-salt-selection v-model="salt" />
    <hr class="sliders-separator">
    <formulator-additive-selection
      :value="this.additives[0]"
      :error-message="errorMessageAdditives[0]"
      @input="val => updateAdditiveSelector({ index: 0, value: val })"
      class="additive-selection"
    />
    <hr
      v-if="updateShowExtension"
      class="sliders-separator"
    >
    <formulator-extension
      :additives-to-show="this.allAdditives"
      :error-messages="errorMessageAdditives"
      @update-show="updateShowExtension"
      @update-range-value="updateRangeValue"
      @update-additive-selector="updateAdditiveSelector"
    />
    <hr class="sliders-separator">
    <formulator-finishing class="formulator__sliders" />
    <hr class="sliders-separator">
    <div class="formulator__finish-box">
      <div class="input__button">
        <button
          id="nutrition-formulator-btn"
          class="formulator__button"
          @click.prevent="calculate"
        >
          Calcular
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import evSelect from '../ev-select.vue';
import formulatorSlider from './formulator-slider.vue';
import formulatorFinishing from './formulator-finishing.vue';
import FormulatorExtension from './formulator-extension';
import FormulatorSaltSelection from './formulator-salt-selection';
import FormulatorAdditiveSelection from './formulator-additive-selection';

export default {
  components: {
    evSelect,
    formulatorSlider,
    formulatorFinishing,
    FormulatorExtension,
    FormulatorSaltSelection,
    FormulatorAdditiveSelection,
  },
  data() {
    return {
      localErrorMessage: this.errorMessage,
      errorMessageAdditives: [false, false, false],
    };
  },
  computed: {
    protein: {
      get() {
        return this.$store.state.formulator.nutritionFormulator.protein;
      },
      set(value) {
        this.$store.commit('updateNutritionFormulator', { protein: value });
      },
    },
    energy: {
      get() {
        return this.$store.state.formulator.nutritionFormulator.energy;
      },
      set(value) {
        this.$store.commit('updateNutritionFormulator', { energy: value });
      },
    },
    fdn: {
      get() {
        return this.$store.state.formulator.nutritionFormulator.fdn;
      },
      set(value) {
        this.$store.commit('updateNutritionFormulator', { fdn: value });
      },
    },
    cne: {
      get() {
        return this.$store.state.formulator.nutritionFormulator.cne;
      },
      set(value) {
        this.$store.commit('updateNutritionFormulator', { cne: value });
      },
    },
    salt: {
      get() {
        return this.$store.state.formulator.nutritionFormulator.salt;
      },
      set(value) {
        this.$store.commit('updateNutritionFormulator', { salt: value });
      },
    },
    additives: {
      get() {
        return this.$store.state.formulator.nutritionFormulator.additives;
      },
      set(value) {
        this.$store.commit('updateNutritionFormulator', { additives: value });
      },
    },
    additivesToShow() {
      return this.additives.slice(0, this.additiveCounter);
    },
    allAdditives() {
      return this.additives;
    },

    additiveCounter: {
      get() {
        return this.$store.state.formulator.nutritionFormulator.additiveCounter;
      },
      set(value) {
        this.$store.commit('updateNutritionFormulator', { additiveCounter: value });
      },
    },
    ingredientRanges() {
      return this.$store.state.formulator.nutritionFormulator.ingredientRanges;
    },
    ingredientRangesForCheapestRecipe() {
      if (!this.ingredientRanges) return null;

      return Object.entries(this.ingredientRanges).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: this.rangeValueArrayToIngredientRangeObject(value) }),
        {},
      );
    },
    packaging() {
      return this.$store.state.recipe.finishing.packaging.value;
    },
    form() {
      return this.$store.state.recipe.finishing.form.value;
    },
    totalSackWeight() {
      return this.$store.state.recipe.finishing.totalSackWeight;
    },
    ingredients() {
      return this.$store.getters.generalIngredients;
    },
  },
  methods: {
    updateAdditiveSelector({ index, value }) {
      this.additives[index] = value;
      this.additives = [...this.additives];
      this.$store.commit('updateNutritionFormulator', { additives: this.additives });
    },

    updateShowExtension(value) {
      this.$store.commit('updateNutritionFormulator', { showExtension: value });
    },
    updateRangeValue({ id, value }) {
      this.$store.commit('updateNutritionFormulator', {
        ingredientRanges: {
          ...this.ingredientRanges,
          [id]: value,
        },
      });
    },

    rangeValueArrayToIngredientRangeObject(rangeValue) {
      return { min: rangeValue[0], max: rangeValue[1] };
    },
    calculate() {
      this.calculateCheapestRecipe();
    },
    checkDuplicatedAdditives() {
      const filteredAdditives = this.additives.filter((additive) => additive.amount !== 0);
      const additivesIds = filteredAdditives.map((additive) => additive.id);
      const uniqueAdditivesIds = [...new Set(additivesIds)];
      if (additivesIds.length !== uniqueAdditivesIds.length) {
        this.localErrorMessage = true;
        this.errorMessageAdditives = this.additives.map((additive) =>
          additivesIds.indexOf(additive.id) !== additivesIds.lastIndexOf(additive.id) && additive.amount !== 0,
        );

        return true;
      }
      this.localErrorMessage = false;
      this.errorMessageAdditives = [false, false, false];

      return false;
    },
    calculateCheapestRecipe() {
      const additivesData = {
        additives: this.additives.filter((additive) => additive.id !== null).map((additive) => ({
          id: additive.id, amount: additive.amount,
        })),
      };
      if (this.checkDuplicatedAdditives(additivesData)) return;

      const params = {
        pc: this.protein,
        em: this.energy,
        fdn: this.fdn,
        cne: this.cne,
        salt: this.salt.amount,
        saltId: this.salt.id,
        ...additivesData,
        form: this.form,
        packaging: this.packaging,
        ingredientRanges: this.ingredientRangesForCheapestRecipe,
        totalSackWeight: this.totalSackWeight,
      };

      this.$store.dispatch('cheapestRecipe', params);
      this.$store.commit('updateVia', 'recipe');
    },
    addAdditiveSelector() {
      if (this.additiveCounter < 3) {
        this.additiveCounter += 1;
        this.additives[this.additiveCounter - 1] = { id: null, amount: 0 };
        this.additives = [...this.additives];
      }
    },
    removeAdditiveSelector(index) {
      if (this.additiveCounter > 1) {
        this.additiveCounter -= 1;
        this.additives[index] = { id: null, amount: 0 };
        this.additives = [...this.additives];
      }
    },
    getTransformClass(n) {
      const hasMinus = n > 1;
      const hasPlus = n === this.additiveCounter && this.additiveCounter < 3;
      if (hasPlus && hasMinus) {
        return 'both-buttons';
      }

      return 'single-button';
    },
  },
  mounted() {
    if (!this.$store.state.firstRecipeLoaded) {
      this.calculateCheapestRecipe();
      this.$store.commit('updateFirstRecipeLoaded', true);
    }

    if (!this.additives.length) {
      this.$store.commit('updateNutritionFormulator', {
        additives: [{ id: null, amount: 0 }],
      });
    }
  },
  props: {
    errorMessage: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.additive-row {
  display: flex;
  align-items: center;
  position: relative;
}

.additive-selection {
  flex: 1;
}

.additive-buttons {
  position: absolute;
  right: 0;
  margin-right: 2.7px;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.additive-buttons.both-buttons {
  transform: translateY(-25%);
}

.additive-buttons.single-button {
  transform: translateY(0%);
}

.add-button,
.remove-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid grey;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  padding: 0;
}

.add-button:hover,
.remove-button:hover {
  background-color: #f0f0f0;
}

@media (max-width: 600px) {
  .additive-buttons {
    position: static;
    transform: none;
    margin-top: 10px;
  }

  .add-button,
  .remove-button {
    margin-left: 0;
  }

  .additive-buttons.both-buttons {
    transform: none;
  }
}
</style>
