<template>
  <div>
    <div v-if="show">
      <div class="additive-row">
        <formulator-additive-selection
          :value="additivesToShow[1]"
          :error-message="errorMessages[1]"
          class="additive-selection"
          @input="val => handleAdditiveChange(1, val)"
          :enableInfoModal="false"
        
        />
      </div>
      <hr class="sliders-separator" v-if="show" />
      <div class="additive-row">
        <formulator-additive-selection
          :value="additivesToShow[2]"
          :error-message="errorMessages[2]"
          class="additive-selection"
          @input="val => handleAdditiveChange(2, val)"
          :enableInfoModal="false"
        />
      </div>
      <hr class="sliders-separator" v-if="show" />
    </div>
    <div class="formulator__sliders formulator__sliders--extension" v-if="show">
      <formulator-slider
        v-for="ingredient in ingredients"
        :key="ingredient.name"
        :name="ingredient.name"
        unit="%"
        :min="ingredient.min"
        :max="ingredient.max"
        :interval="ingredient.interval"
        :value="ingredientRanges[ingredient.id]"
        @input="val => updateRangeValue(ingredient.id, val)"
      />
    </div>
    <hr class="sliders-separator" v-if="show" />
    <div class="formulator__extension-btn">
      <button class="btn btn-none btn--h-center" @click="updateShow">
        <div class="dropdown-icon" :class="{ 'dropdown-icon--up': show }"></div>
      </button>
    </div>
  </div>
</template>
<script>
import formulatorSlider from './formulator-slider.vue';
import formulatorAdditiveSelection from './formulator-additive-selection.vue';

export default {
  name: 'FormulatorExtension',
  components: {
    formulatorSlider,
    formulatorAdditiveSelection,
  },
  props: {
    // Array completo de aditivos
    additivesToShow: {
      type: Array,
      required: true,
    },
    errorMessages: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    show() {
      return this.$store.state.formulator.nutritionFormulator.showExtension;
    },
    ingredients() {
      return this.$store.getters.generalIngredients;
    },
    ingredientRanges() {
      return this.$store.state.formulator.nutritionFormulator.ingredientRanges;
    },
  },
  methods: {
    updateShow() {
      this.$emit('update-show', !this.show);
    },
    updateRangeValue(id, value) {
      this.$emit('update-range-value', { id, value });
    },
    // Emite un evento al padre cuando cambian los aditivos (índice 1, 2, etc.)
    handleAdditiveChange(index, newVal) {
      this.$emit('update-additive-selector', { index, value: newVal });
    },
  },
};
</script>
<style scoped>
.additive-row {
  display: flex;
  align-items: center;
}

.additive-selection {
  flex: 1;
}

.sliders-separator {
  /* margin: 1em 0; */
}
</style>
