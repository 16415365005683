<template>
  <div>
    <div v-if="show">
      <div class="additive-row">
        <formulator-additive-selection
          :value="additivesToShow[1]"
          :error-message="errorMessages[1]"
          class="additive-selection"
          @input="val => handleAdditiveChange(1, val)"
          :enable-info-modal="true"
        />
      </div>
      <hr
        class="sliders-separator"
        v-if="show"
      >
      <div class="additive-row">
        <formulator-additive-selection
          :value="additivesToShow[2]"
          :error-message="errorMessages[2]"
          class="additive-selection"
          @input="val => handleAdditiveChange(2, val)"
          :enable-info-modal="false"
        />
      </div>
      <hr
        class="sliders-separator"
        v-if="show"
      >
    </div>
    <div class="formulator__extension-btn">
      <button
        class="btn btn-none btn--h-center"
        :style="{ 'margin-top': show ? '0' : '0' }"
        @click="updateShow"
      >
        <div
          class="dropdown-icon"
          :class="{ 'dropdown-icon--up': show }"
        />
      </button>
    </div>
  </div>
</template>

<script>
import formulatorAdditiveSelection from './formulator-additive-selection.vue';

export default {
  name: 'FormulatorExtensionAdditives',
  components: {
    formulatorAdditiveSelection,
  },
  props: {
    additivesToShow: {
      type: Array,
      required: true,
    },
    errorMessages: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    show() {
      return this.$store.state.formulator.ingredientFormulator.showExtensionIngredients;
    },
  },
  methods: {
    updateShow() {
      this.$emit('update-show', !this.show);
    },
    handleAdditiveChange(index, newVal) {
      this.$emit('update-additive-selector', { index, value: newVal });
    },
  },
};
</script>

<style scoped>
.additive-row {
  display: flex;
  align-items: center;
}

.additive-selection {
  flex: 1;
}

</style>
