<template>
  <div class="formulator-slider">
    <div v-if="withInput" class="formulator-slider__header">
      <p class="formulator-slider__title">{{ name }}</p>
      <div class="formulator-slider__input">
        <input
          ref="input"
          type="number"
          :value="value"
          step="any"
          @input="updateFromInput($event)"
        />
      </div>
    </div>
    <template v-else>
      <div class="formulator-slider__title">
        <div v-if="isRange" class="flex">
          <div>{{ name }}</div>
          <div class="ml-auto">
            min <b>{{ value[0] }} {{ unit }}</b> - max <b>{{ value[1] }} {{ unit }}</b>
          </div>
        </div>
        <div v-else>
          <slot name="title">
            {{ name }} <b>{{ disabled ? 0 : value }} {{ unit }}</b>
          </slot>
          <img
            class="formulator__sliders-info-tag"
            v-if="infoModal"
            @click="openInfo(infoModal)"
          />
        </div>
      </div>
    </template>

    <div class="formulator-slider__slider">
      <vue-slider
        @input="updateValue($event)"
        :value="value"
        :disabled="disabled"
        v-bind="sliderConfiguration"
      />
    </div>
  </div>
</template>

<script>
import autosizeInput from 'autosize-input';
import vueSlider from 'vue-slider-component';

export default {
  components: {
    vueSlider,
  },
  mounted() {
    this.autosizeInput();
  },
  methods: {
    // Autosize el input solo si withInput = true
    autosizeInput() {
      if (!this.withInput) return;
      if (this.destroyAutosize) this.destroyAutosize();
      this.destroyAutosize = autosizeInput(this.$refs.input);
    },

    updateFromInput(e) {
      let value = parseFloat(e.target.value);
      if (!isNaN(value)) {
        this.updateValue(value);
      }
    },

    updateValue(e) {
      // Evita emitir si el valor no ha cambiado o si hay valores undefined en modo rango
      if (e === this.value || (this.isRange && e.some(item => item === undefined))) {
        return;
      }
      this.$emit('input', e);
      this.$store.dispatch('setUpdated', false);
    },

    openInfo(infoModal) {
      this.$modal.show(infoModal);
    },

    /**
     * Genera un arreglo de valores que:
     * - Empieza en 0
     * - Salta a 0.5
     * - Continua sumando 0.1 hasta 2
     */
    generateDataArray() {
      const result = [0];
      let current = 0.5;
      result.push(current);

      while (current < 2.05) {
        current += 0.1;
        if (current < 2.05) {
          result.push(parseFloat(current.toFixed(1)));
        }
      }
      return result;
    },
  },

  computed: {
    isRange() {
      return Array.isArray(this.value);
    },
  },

  props: {
    min: {
      type: [Number, String],
      required: true,
    },
    max: {
      type: [Number, String],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, String, Array],
      required: true,
    },
    interval: {
      type: [Number, String],
      required: true,
    },
    withInput: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    infoModal: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sliderConfiguration: {
        // El min y max "visuales"
        min: 0,
        max: 2,
        // data hace que el slider solo pueda tomar esos valores
        data: this.generateDataArray(),
        // Si igualmente quieres que la perilla se mueva "en pasos",
        // puedes dejar interval = 0.05, aunque no es estrictamente necesario
        interval: 0.05,
        width: '100%',
        tooltip: false,
        disabled: false,
        piecewise: true,
        piecewiseLabel: false,
        style: {
          marginLeft: '0%',
        },
        piecewiseStyle: {
          backgroundColor: '#ccc',
          visibility: 'hidden',
          width: '12px',
          height: '12px',
        },
        piecewiseActiveStyle: {
          backgroundColor: '#3498db',
        },
        labelStyle: {
          color: '#1c1c1c',
          marginTop: '-35px',
        },
        labelActiveStyle: {
          color: '#fe5001',
        },
        processStyle: {
          backgroundImage: '-webkit-linear-gradient(left, #ff905d, #fe5001)',
        },
        sliderStyle: {
          backgroundColor: '#fe5001',
        },
        tooltipStyle: {
          backgroundColor: '#ff905d',
          borderColor: '#ff905d',
        },
      },
    };
  },

  watch: {
    value() {
      this.$nextTick(() => this.autosizeInput());
    },
  },
};
</script>
