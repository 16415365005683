<template>
  <div id="formulator-ingredients">
    <div class="formulator__sliders">
      <formulator-slider
        v-for="ingredient in ingredients"
        :key="ingredient.name"
        :name="ingredient.name"
        :min="ingredient.min"
        :max="ingredient.max"
        :interval="ingredient.interval"
        :value="ingredientFormulatorIngredients[ingredient.id]"
        @input="(val) => updateValue(ingredient.id, val)"
        unit="%"
        with-input
        is-ingredient
      />
    </div>
    <hr class="sliders-separator">
    <formulator-salt-selection
      :value="ingredientFormulatorSalts[0]"
      @input="(val) => updateSalt(0, val)"
      :ignored-salts="[ingredientFormulatorSalts[1]]"
    />
    <formulator-salt-selection
      v-if="isCoagraWeb"
      :value="ingredientFormulatorSalts[1]"
      @input="(val) => updateSalt(1, val)"
      :ignored-salts="[ingredientFormulatorSalts[0]]"
    />
    <hr class="sliders-separator">
    <div v-if="!isCoagraWeb">
    <formulator-additive-selection
    
      :value="this.additives[0]"
      :error-message="errorMessageAdditives[0]"
      @input="val => updateAdditiveSelectorIngredients({ index: 0, value: val })"
      class="additive-selection"
    />
    <hr
      v-if="updateShowExtensionIngredients"
      class="sliders-separator"
    >
    <formulator-extension-additives
      :additives-to-show="this.additivesToShow"
      :error-messages="errorMessageAdditives"
      @update-additive-selector="updateAdditiveSelectorIngredients"
      @update-show="updateShowExtensionIngredients"
    />
    <hr class="sliders-separator">
    </div>
    <formulator-finishing class="formulator__sliders formulator-finishing" />
    <hr class="sliders-separator">
    <div class="formulator__finish-box">
      <div
        class="input__button_ingredients"
        style="display: flex; align-items: center;"
      >
        <button
          id="ingredients-formulator-btn"
          class="formulator__button"
          :disabled="total != 100"
          @click="getInverseRecipe()"
        >
          Calcular
        </button>

        <div
          class="ingredients-warning select-group__title"
          style="flex: none; margin-right: 10px; display: flex; align-items: center; justify-content: center;"
        >
          Total:
          <div
            style="flex:1; min-width: 50px; text-align: right; display: flex; align-items: center; justify-content: center; flex-direction: column;"
          >
            <b><span
              :class="{ 'ingredients-warning__error': total != 100 } "
            >{{ total }} </span>%</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FormulatorSlider from './formulator-slider';
import FormulatorFinishing from './formulator-finishing';
import formulatorAdditiveSelection from './formulator-additive-selection.vue';
import FormulatorSaltSelection from './formulator-salt-selection';
import FormulatorExtensionAdditives from './formulator-extension-additives.vue';

export default {
  components: {
    FormulatorSlider,
    FormulatorFinishing,
    formulatorAdditiveSelection,
    FormulatorSaltSelection,
    FormulatorExtensionAdditives,
  },
  data() {
    return {
      localErrorMessage: this.errorMessage,
      errorMessageAdditives: [false, false, false],
    };
  },
  computed: {
    ingredients() {
      return this.$store.getters.generalIngredients;
    },
    total() {
      const ingredientSum = Object.entries(this.ingredientFormulatorIngredients).reduce((total, [, percentage]) =>
        total + parseFloat(percentage), 0);
      const saltsSum = this.ingredientFormulatorSalts.reduce((total, salt) =>
        total + parseFloat(salt.amount), 0);
      const additivesSum = this.additives.reduce((total, additive) =>
        total + parseFloat(additive.amount), 0);

      return (ingredientSum + saltsSum + additivesSum).toFixed(1);
    },
    isCoagraWeb() {
      return this.$store.state.order.isCoagraWeb;
    },
    ingredientFormulatorIngredients() {
      return this.$store.state.formulator.ingredientFormulator.ingredients;
    },
    ingredientFormulatorSalts() {
      return this.$store.state.formulator.ingredientFormulator.salts;
    },
    inverseRecipeIngredients() {
      const query = Object.entries(this.ingredientFormulatorIngredients).map(([id, percentage]) =>
        ({ id, value: percentage }),
      );
      this.ingredientFormulatorSalts.filter(salt => salt.id && salt.amount > 0.0).forEach(salt => {
        (query.push({ id: salt.id, value: salt.amount }));
      });
      this.additives.filter(additive => additive.id && additive.amount > 0.0).forEach(additive => {
        (query.push({ id: additive.id, value: additive.amount }));
      });

      return query;
    },
    additives: {
      get() {
        return this.$store.state.formulator.ingredientFormulator.additives;
      },
      set(value) {
        this.$store.commit('updateIngredientFormulator', { additives: value });
      },
    },
    additivesToShow() {
      return this.additives;
    },
    allAdditives() {
      return this.additives;
    },
    additiveCounter: {
      get() {
        return this.$store.state.formulator.ingredientFormulator.additiveCounter;
      },
      set(value) {
        this.$store.commit('updateIngredientFormulator', { additiveCounter: value });
      },
    },
  },
  methods: {
    updateShowExtensionIngredients(value) {
      this.$store.commit('updateIngredientFormulator', { showExtensionIngredients: value });
    },
    checkDuplicatedAdditives() {
      const filteredAdditives = this.additives.filter((additive) => additive.amount !== 0);
      const additivesIds = filteredAdditives.map((additive) => additive.id);
      const uniqueAdditivesIds = [...new Set(additivesIds)];
      if (additivesIds.length !== uniqueAdditivesIds.length) {
        this.localErrorMessage = true;
        this.errorMessageAdditives = this.additives.map((additive) =>
          additivesIds.indexOf(additive.id) !== additivesIds.lastIndexOf(additive.id) && additive.amount !== 0,
        );

        return true;
      }
      this.localErrorMessage = false;
      this.errorMessageAdditives = [false, false, false];

      return false;
    },
    getInverseRecipe() {
      if (Number(this.total) !== 100) return; // eslint-disable-line no-magic-numbers
      if (this.checkDuplicatedAdditives()) return;

      this.$store.dispatch('inverseRecipe', { ingredients: this.inverseRecipeIngredients });
      this.$store.commit('updateVia', 'ingredient');
    },
    updateValue(id, value) {
      this.$store.commit('updateIngredientFormulatorIngredients', { [id]: value });
    },
    updateSalt(index, value) {
      this.$store.commit('setIngredientFormulatorSalt', { index, value });
    },
    updateAdditiveSelectorIngredients({ index, value }) {
      this.additives[index] = value;
      this.additives = [...this.additives];
      this.$store.commit('updateIngredientFormulator', { additives: this.additives });
    },
  },
};
</script>
