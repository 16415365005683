<template>
  <div>
    <formulator-slide-and-select
      name="Aditivos"
      unit="%"
      min="0"
      max="2"
      :interval="0.05"
      :error-message="errorMessage"
      v-model="additive"
      :info-modal="enableInfoModal ? infoModal : null"
      :items="additives"
      :update-on-select="true"
    />
  </div>
</template>

<script>
import FormulatorSlideAndSelect from './formulator-slide-and-select-additive';

export default {
  components: {
    FormulatorSlideAndSelect,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    ignoredAdditives: {
      type: Array,
      default: () => [],
      required: false,
    },
    enableInfoModal: {
      type: Boolean,
      default: true,
      required: false,
    },
    errorMessage: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    infoModal() {
      if (this.$store.state.order.isCoagraWeb) {
        return null;
      }

      return 'additive-modal';
    },
    ignoredAdditiveIds() {
      return this.ignoredAdditives.map(additive => additive.id);
    },
    additives() {
      return this.$store.getters.ingredientsForSelect(['additive']).filter(ingredient =>
        !this.ignoredAdditiveIds.includes(ingredient.value),
      );
    },
  },
  data() {
    return {
      additive: this.value,
    };
  },
  watch: {
    additive(val) {
      this.$emit('input', val);
    },
  },
};
</script>
